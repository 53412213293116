import { Global, ThemeProvider } from "@emotion/react";
import { theme } from "@packages/ui/emotion";
import { globalStyle } from "@packages/ui/style";
import type { AppProps } from "next/app";
import Head from "next/head";
import favicon from "../public/images/favicon.ico";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={() => globalStyle(theme)} />
      <Head>
        <link rel="icon" href={favicon.src} />
        <title>KonopnyMed</title>
      </Head>
      <Component {...pageProps} />
    </ThemeProvider>
  );
}

export default MyApp;
