import { css, Theme } from "@emotion/react";

export const globalStyle = (theme: Theme | Record<string, any>) => css`
  @import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

  body {
    margin: 0;
    padding: 0;
    background-color: ${theme.colors.bg};

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
  }

  body,
  button,
  input,
  textarea {
    font-family: "Space Grotesk", sans-serif;
  }

  p {
    font-weight: 400;
    line-height: 150%;
    color: ${theme.colors.gray2};
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${theme.colors.navy};
    margin: 0;
  }

  p {
    margin: 0;
  }
`;
