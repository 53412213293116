import { Theme } from "@emotion/react";

export const colors = {
  brand: "#EE785E",
  lightOrange: "#FFBA8B",
  darkOrange: "#E45A20",
  siena: "#95330A",
  navy: "#193561",
  dark: "#1A1833",
  almostBlack: "#1A2A43",
  red: "#C53030",
  gray2: "#4F4F4F",
  gray6: "#F2F2F2",
  bg: "#FFF8EF",
};

export const fonts = {
  sizes: {
    xxxxs: "11px",
    xxxs: "14px",
    xxs: "16px",
    base: "18px",
    xs: "20px",
    s: "24px",
    m: "28px",
    l: "32px",
    xl: "48",
    xxl: "64px",
  },
  weights: {
    thin: 300,
    normal: 400,
    medium: 500,
    bold: 600,
    bolder: 700,
    black: 900,
  },
};

export const mq = {
  mobileS: "(max-width: 320px)",
  mobileM: "(max-width: 375px)",
  mobileL: "(max-width: 425px)",
  tablet: "(max-width: 768px)",
  nonMobile: "(min-width: 769px)",
  laptop: "(max-width: 1024px)",
  laptopM: "(max-width: 1260px)",
  laptopL: "(max-width: 1440px)",
  ultraWide: "(min-width: 1440px)",
};

export const theme: Theme = {
  mq,
  fonts,
  colors,
};
